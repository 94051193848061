<template>
  <card no-padding>
    <card-header class="bg-brand-dark radius-top-5 p-3 text-white" :title="$t('New note')"></card-header>
    <div class="p-4">
      <ckeditor class="border-light border" :editor="InlineEditor" v-model="new_note" :config="editorConfig"></ckeditor>
      <div class="mt-2 text-right">
        <ion-spinner v-if="noteAddingSpinner"></ion-spinner>
        <nk-button v-else type="dark" v-on:click="add_new_note">{{ $t('Save note') }}</nk-button>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@core/components/cards/Card";
import CardHeader from "@core/components/cards/CardHeader";
import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor"

import {ref} from "vue";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import axios from "@/libs/axios";
import {toastController} from "@ionic/vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";

export default {
  components: {CardHeader, Card, ckeditor: CKEditor.component,},
  emits: ['onNewNoteAddition'],
  setup( props, {emit} ){

    const editorConfig = {
      plugins: [BoldPlugin, EssentialsPlugin, ItalicPlugin, LinkPlugin, ParagraphPlugin,],
      removePlugins: ['Heading'],
      toolbar: [ 'bold', 'italic', 'link'],
    }
    let new_note          = ref('')
    let noteAddingSpinner = ref(false)
    const route           = useRoute()
    const store           = useStore()
    const{t}              = useI18n()

    const add_new_note = async () => {
      if(new_note.value.length < 1){
        return false;
      }
      noteAddingSpinner.value = true
      let endPoint = {
        'is-advisor': 'advisor',
        'is-ap-agent': 'ap_agent'
      }
      axios.post('/api/'+(endPoint[store.state.auth.user_role] || '')+'/lead/add_note', {id: route.params.id, note: new_note.value})
          .then( (resp) => {
            if(!resp.data.success){
              toastController.create({color: 'danger', message: resp.data.message, duration:3000}).then( toast => toast.present())
            }
            else{
              new_note.value = ''
              emit('onNewNoteAddition', resp.data.data)
              toastController.create({color: 'success', message: t('Thank you! note added'), duration:2000}).then( toast => toast.present())
            }
          })
          .catch(err => {
            toastController.create({color: 'warning', message: t('errors.general_error'), duration:3000}).then( toast => toast.present())
            console.log('Note adding ajax error: '+err)
          })
          .then(() => noteAddingSpinner.value = false)
    }

    return{
      add_new_note,
      editorConfig,
      InlineEditor,
      new_note,
      noteAddingSpinner,
    }
  }
}
</script>
