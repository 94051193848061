<template>
  <row class="g-gs">
    <column default="12">
      <card>
        <!-- if current user is an appointment agent -->
        <template v-if="userRole === 'is-ap-agent'">
          <h4 style="color: #00c58c;">{{ $t("appointment_feedback.title1")}}</h4>
          <p :class="{'text-danger': !leadData.next_followup.future_date}"> {{$t('Appointment')+' '+$t('at')+' '+(leadData.next_followup.future_date || leadData.next_followup.date)}}</p>
          <!-- if lead is not yet assigned to an advisor -->
          <template v-if="!leadIsAssigned">
            <!-- appointment date is in future -->
            <template v-if="leadData.next_followup.future_date">
              <nk-button  type="light" round outline
                          v-on:click="[currentStatus='', scrollToBottom()]">{{ $t('Assign appointment to advisor') }} <nio-icon icon="ni-arrow-long-down"></nio-icon></nk-button>
              <p class="mt-2">{{ $t("appointment_feedback.take_action_if_ap_changed") }}</p>
            </template>
            <p v-else>{{ $t("appointment_feedback.missed_assigning_ap") }}</p>
            <nk-button type="info" round
                       class="mr-2 mb-2 mb-lg-0"
                       :class="{'w-100 d-block': $isHybridPlatform}"
                       size="sm"
                       v-on:click="setCurrentStatus('rescheduled_by_me')"
                       :outline="currentStatus !== 'rescheduled_by_me'">{{$t('Appointment rescheduled by me')}}</nk-button>
            <nk-button type="info" round
                       class="mr-2 mb-2 mb-lg-0"
                       size="sm"
                       :class="{'w-100 d-block': $isHybridPlatform}"
                       v-on:click="setCurrentStatus('rescheduled_by_customer')"
                       :outline="currentStatus !== 'rescheduled_by_customer'">{{$t('Appointment rescheduled by customer')}}</nk-button>
            <nk-button type="danger" round
                       size="sm"
                       :class="{'w-100 d-block': $isHybridPlatform}"
                       v-on:click="setCurrentStatus('canceled_by_customer')"
                       :outline="currentStatus !== 'canceled_by_customer'">{{$t('Appointment cancelled by customer')}}</nk-button>
          </template>
        </template>
        <template v-else> <!-- else if current user is an advisor -->
          <template v-if="leadData.next_followup.future_date">
            <h4 style="color: #00c58c;">{{ $t("lead_followup_wizard.upcoming_followup_type_reminder", {followup_type: $t(leadData.next_followup.type).toLowerCase() })}}</h4>
            <p> {{$t(leadData.next_followup.type)+' '+$t('at')+' '+leadData.next_followup.future_date}}</p>
          </template>
          <template v-else>
            <h4 style="color: #00c58c;">{{ $t("appointment_feedback.title")}}</h4>
            <div class="my-4">
              <nk-button type="success" round
                         class="mr-2 mb-2 mb-lg-0"
                         :class="{'w-100 d-block': $isHybridPlatform}"
                         size="sm"
                         v-on:click="setCurrentStatus('had_appointment')"
                         :outline="currentStatus !== 'had_appointment'">{{$t('Had appointment')}}</nk-button>
              <nk-button type="info" round
                         class="mr-2 mb-2 mb-lg-0"
                         :class="{'w-100 d-block': $isHybridPlatform}"
                         size="sm"
                         v-on:click="setCurrentStatus('rescheduled_by_me')"
                         :outline="currentStatus !== 'rescheduled_by_me'">{{$t('Appointment rescheduled by me')}}</nk-button>
              <nk-button type="info" round
                         class="mr-2 mb-2 mb-lg-0"
                         :class="{'w-100 d-block': $isHybridPlatform}"
                         size="sm"
                         v-on:click="setCurrentStatus('rescheduled_by_customer')"
                         :outline="currentStatus !== 'rescheduled_by_customer'">{{$t('Appointment rescheduled by customer')}}</nk-button>
              <nk-button type="danger" round
                         size="sm"
                         :class="{'w-100 d-block': $isHybridPlatform}"
                         v-on:click="setCurrentStatus('canceled_by_customer')"
                         :outline="currentStatus !== 'canceled_by_customer'">{{$t('Appointment cancelled by customer')}}</nk-button>
            </div>
          </template>
        </template>

        <div class="my-2"></div>

        <div v-if="['rescheduled_by_me', 'rescheduled_by_customer', 'canceled_by_customer'].includes(currentStatus)">
          <p class="fw-bold mb-0">{{$t('What was the reason?')}}</p>
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <textarea class="form-control mt-2" :placeholder="$t('Reason')" v-model="reason"></textarea>
            </div>
          </div>
        </div>
        <div v-if="['rescheduled_by_me', 'rescheduled_by_customer'].includes(currentStatus)" class="mt-2">
          <p class="fw-bold mb-0">{{$t('Next appointment date')}}</p>
          <lead-next-followup :date="nextApDate.date" :hour="nextApDate.hour" :min="nextApDate.min" @update-data="syncNextApDate"></lead-next-followup>
        </div>
        <div v-if="currentStatus" class="mt-2">
          <nk-button type="success" round outline
                     :class="{'w-100 d-block': $isHybridPlatform}"
                     class="mr-2"
                     v-on:click="submitFollowupResult">{{$t('Submit')}}</nk-button>
        </div>

      </card>
    </column>
  </row>
</template>

<script>
import {reactive, ref} from "vue";
import Card from "@core/components/cards/Card";
import {Column,Row} from "@core/layouts";
import 'flatpickr/dist/flatpickr.css';
import useCurrentPlatform from "@/store/currentPlatform";
import {loadingController, toastController} from "@ionic/vue"
import {Dutch} from "flatpickr/dist/l10n/nl";
import {english} from "flatpickr/dist/l10n/default";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import LeadNextFollowup from "@/views/lead-details/LeadNextFollowup";
import axios from "@/libs/axios";
import NkButton from "@core/components/button/NkButton";
import useIonComponents from '@core/IonComponents'
import useStoreHelper from '@/store/helper'

export default {
  components: {
    NkButton,
    LeadNextFollowup, Column, Row, Card},
  props:{
    leadData: Object,
    leadIsAssigned: null,
    leadType: {
      required: true,
      type: String,
    },
    starRating: {
      required: true,
    },
    advisorLeadId: {
      required: true,
    },
  },
  emits: ['onRatingChange', 'onLeadDataUpdate', 'onScrollBottom'],
  setup(props, {emit}){

    const { platform } = useCurrentPlatform()
    const store = useStore()
    const {t} = useI18n()
    const{IonSimpleAlert} = useIonComponents()
    const{endPointByRole, userRole} = useStoreHelper()

    const vueFlatPickerConfig = {
      dateFormat: 'Y-m-d',
      altFormat: 'd/m/Y',
      altInput: true,
      enableTime: false,
      minDate: props.leadData.last_followup.date_iso || props.leadData.assigned_date_ISO,
      maxDate: new Date(),
      locale: store.state.appConfig.lang === 'nl' ? Dutch: english,
    }

    // Next appointment date
    let nextApDate = reactive({
      date: '',
      hour: '',
      min: '',
    })

    let currentStatus = ref('')
    let reason = ref('')

    const syncNextApDate = (d)=>{
      nextApDate.date = d.date
      nextApDate.hour = d.hour
      nextApDate.min  = d.min
    }

    const submitFollowupResult = async () => {

      let params = {}
      params.status = currentStatus.value
      params.reason = reason.value
      if(['rescheduled_by_me', 'rescheduled_by_customer', 'canceled_by_customer'].includes( params.status) ){
        if(!params.reason.length){
          await IonSimpleAlert('Error', 'Please provide a reason', 'OK')
          return false;
        }
      }
      else{
        params.reason = ''
      }

      if( ['rescheduled_by_me', 'rescheduled_by_customer'].includes( params.status) ){
        if(!nextApDate.date || !nextApDate.hour || !nextApDate.min){
          await IonSimpleAlert('Error', 'Please provide a valid date & time', 'OK')
          return false;
        }
        params.next_date = nextApDate.date+' '+nextApDate.hour+':'+nextApDate.min;
      }

      const loading = await loadingController.create({message: t('Please wait...')})
      await loading.present()

      axios.put(endPointByRole+'/lead/'+props.advisorLeadId+'/submit_ap_feedback', params)
      .then(res=>{
        emit('onLeadDataUpdate', res.data.data)
        currentStatus.value = ''
      })
      .catch(er=>{
        const msg = er?.response?.data ? er.response.data.message : t('errors.general_error')
        toastController.create({header: t('Error'), message: msg, color:"danger", position: 'top', duration: 4000}).then((ts)=> ts.present())
      })
      .then(()=> loading.dismiss())
    }

    const setCurrentStatus = (s)=>{
      currentStatus.value = s;
    }

    const scrollToBottom = ()=>{
      emit('onScrollBottom')
    }

    return{
      currentStatus,
      nextApDate,
      platform,
      reason,
      submitFollowupResult,
      syncNextApDate,
      vueFlatPickerConfig,
      scrollToBottom,
      setCurrentStatus,
      userRole,
    }
  }
}
</script>
