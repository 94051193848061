<template>
  <page-template>
    <main-block>
      <block-content v-if="displaySpinner">
        <div class="text-center pt-5"><ion-spinner></ion-spinner></div>
      </block-content>
      <block-content v-else>
        <card class="p-2 mb-3" no-padding>
          <div class="d-flex align-items-center fw-500 h6" :class="[lead_details.archived===1 ? 'text-danger' : 'nk-text-dark']">
            <nk-button type="" is-icon-only-button round v-on:click="$router.go(-1)" class="btn-trigger mr-3">
              <nio-icon icon="ni-arrow-left"></nio-icon>
            </nk-button>
            <div>
              <div>
                    <span v-if="lead_details.archived===1">
                      <nio-icon icon="ni-archived mr-1"></nio-icon>{{ $t('Archived') }} <span class="text-muted">></span>
                    </span>
                {{$t( lead_details.lead_type === 'lead' ? 'Lead' : 'Appointment' + ' Details')}}</div>
              <div class="small mt-1 text-muted fw-500">#{{ lead_details.lead_number }}</div>
            </div>
          </div>
        </card>
        <lead-followup-wizard v-if="lead_details.lead_type === 'lead' && userRole === 'is-advisor' && lead_details.followup_suggestion.id"
                              :lead-data="lead_details.followup_suggestion" :advisor-lead-id="lead_details.AL_id"
                              :lead-type="lead_details.lead_type"
                              @on-rating-change="onRatingChange" :star-rating="lead_details.rating"
                              @on-lead-data-update="onLeadDataUpdate"></lead-followup-wizard>
        <appointment-feedback-wizard v-else-if="displayAppointmentFeedbackComp"
                                     :lead-data="lead_details.followup_suggestion" :advisor-lead-id="lead_details.AL_id"
                                     :lead-type="lead_details.lead_type"
                                     @on-rating-change="onRatingChange" :star-rating="lead_details.rating"
                                     @on-lead-data-update="onLeadDataUpdate"></appointment-feedback-wizard>
        <return-details
            v-if="lead_details.returned === 1"
            :return-details="lead_details.return_details"
            @return-canceled="onLeadReturnCancel"
            :advisor-lead-id="lead_details.AL_id" :lead-number="lead_details.lead_number"></return-details>
        <row class="g-gs" v-if="lead_details.return_details.return_status !== 1">
          <column :xxl="4">
            <div>
              <user-small-card :user-name="lead_details.first_name+' '+lead_details.last_name" :initials="lead_details.initials"></user-small-card>
              <div class="nk-text-muted pt-1">{{ lead_details.street+' '+lead_details.house_no }}<br/>{{ lead_details.post_code}}, {{lead_details.city}}<br/>{{lead_details.province}}</div>

              <row class="mt-2">
                <column class="col-auto">
                  <a :href="'tel:'+lead_details.phone_no" class="btn btn-success btn-xs btn-icon h-100" style="z-index: 1">
                    <nio-icon icon="ni-call"></nio-icon>
                  </a>
                  <badge type="success" :outline="true" :dim="true" :content="lead_details.phone_no" class="h-100 ml-n1 pl-2 align-items-center"></badge>
                </column>
                <column class="col-auto">
                  <nk-button size="xs" type="primary" is-icon-only-button
                             v-on:click="[quickEmail.to=lead_details.AL_id,quickEmail.toEmail = lead_details.email, quickEmail.isModalOpen=true]" class="h-100" style="z-index: 1">
                    <nio-icon icon="ni-mail"></nio-icon>
                  </nk-button>
                  <badge type="primary" :outline="true" :dim="true" :content="lead_details.email" class="h-100 ml-n1 pl-2 align-items-center"></badge>
                </column>
              </row>
            </div>
            <lead-data :lead_data="lead_details.lead_data.length ? lead_details.lead_data : lead_details"></lead-data>
          </column>

          <column :xxl="5">
            <card no-padding id="home_notifications_card">
              <div class="p-4">
                <row class="nk-text-dark" align-v="center">
                  <column :md="5">
                    <div class="font-weight-bold">{{ $t('Your location') }}</div>
                    <div class="nk-text-muted lh-n">{{ currentUserData.post_code+' '+currentUserData.city }}, {{currentUserData.province}}<br/> {{currentUserData.house_no+', '+currentUserData.street}}</div>
                  </column>
                  <column :md="2" class="text-center">
                    <nio-icon icon="ni-arrow-right" style="font-size: 30px;" class="d-none d-md-inline"></nio-icon>
                    <nio-icon icon="ni-arrow-down" style="font-size: 30px;" class="d-inline d-md-none"></nio-icon>
                    <div v-if="distance > 0">
                      <badge :content="'&#177; '+distance+' KM'" pill type="success"></badge>
                    </div>
                  </column>
                  <column :md="5" class="text-md-right mt-2 mt-md-0">
                    <div class="font-weight-bold">{{ lead_details.post_code+', '+lead_details.city+' '+lead_details.province }}</div>
                    <div class="nk-text-muted lh-n">{{ lead_details.house_no }}, {{ lead_details.street }}</div>
                  </column>
                </row>
              </div>
              <lead-location-map
                  map-type="roadmap"
                  :start="currentUserData.latitude+', '+currentUserData.longitude"
                  :end="lead_details.latitude+', '+lead_details.longitude"
                  @distance-changed="updateDistance"></lead-location-map>
            </card>
          </column>

          <column :xxl="3">
            <lead-status-and-rating
                v-model:lead_data="lead_details"
                @on-lead-return-success="onLeadReturnSuccess"
                @on-rating-change="onRatingChange"></lead-status-and-rating>
          </column>
        </row>
        <row class="mt-4" v-if="lead_details.mortgage_calculations.data">
          <column :lg="12">
            <lead-mortgage-calculations :mortgage="lead_details.mortgage_calculations"></lead-mortgage-calculations>
          </column>
        </row>
        <row class="mt-4 mb-5">
          <column :md="12" :lg="8">
            <lead-notes-view :notes="lead_details.notes"></lead-notes-view>
          </column>
          <column :md="12" :lg="4">
            <lead-new-note v-if="userRole === 'is-advisor'" @on-new-note-addition="onNewNoteAddition"></lead-new-note>
          </column>
        </row>
      </block-content>
    </main-block>
    <send-quick-email :to-id="quickEmail.to" :recipient-email="quickEmail.toEmail" :modal-open="quickEmail.isModalOpen" @on-modal-close="quickEmail.isModalOpen=false"></send-quick-email>
  </page-template>
</template>

<script>
import { toastController, IonSpinner, isPlatform } from '@ionic/vue';
import {computed, defineComponent, onMounted, reactive, ref, watch, /*nextTick*/} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card } from '@core/components/cards'
import axios from "@/libs/axios";

import "bootstrap"
//import $ from "jquery"
import { useRoute } from "vue-router"
import LeadData from "@/views/lead-details/LeadData";
import LeadStatusAndRating from "@/views/lead-details/LeadStatusAndRating";
import NkButton from "@core/components/button/NkButton";
import NioIcon from "@core/components/nio-icon/NioIcon";
import Badge from "@core/components/badge/Badge";
import { useI18n } from "vue-i18n"
import LeadLocationMap from "@/views/lead-details/LeadLocationMap";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import ReturnDetails from "@/views/lead-details/ReturnDetails";
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import SendQuickEmail from "@/views/email-templates/SendQuickEmail";
import LeadFollowupWizard from "@/views/lead-details/LeadFollowupWizard";
import useGeneralFunc from "@core/comp-functions/common"
import LeadNewNote from "@/views/lead-details/LeadNewNote";
import LeadNotesView from "@/views/lead-details/LeadNotesView";
import useStoreHelper from '@/store/helper'
import {useStore} from "vuex";
import AppointmentFeedbackWizard from "@/views/lead-details/AppointmentFeedbackWizard";
import LeadMortgageCalculations from "@/views/mortgage-calculations/LeadMortgageCalculations";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    LeadMortgageCalculations,
    AppointmentFeedbackWizard,
    LeadNotesView,
    LeadNewNote,
    LeadFollowupWizard,
    SendQuickEmail,
    ReturnDetails,
    LeadLocationMap,
    Badge,
    NioIcon,
    NkButton,
    LeadStatusAndRating,
    LeadData,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    IonSpinner,
    UserSmallCard,
    /*IonHeader,
    IonTitle,
    IonToolbar,*/
  },
  setup() {

    const {updateTracker} = useDataChangeTracker()
    const {updateObject} = useGeneralFunc()
    const router = useRoute()
    const{endPointByRole, currentUserData, userRole} = useStoreHelper()
    const store = useStore()

    let new_note        = ref('')
    let lead_details    = reactive({
          AL_id: '',
          archived: 0,
          status_id: 0,
          event_date_iso: '',
          event_date: '',
          first_name: '',
          followup_logs: [],
          followup_suggestion:{
            assigned_date_ISO: '',
            attempt: 1,
            id: '',
            last_followup: {date: '', date_iso: '', time: '', time_name: '', result: 0},
            lead_number: '',
            next_followup: {type: "", date: "", future_date: ""},
            received_time: '',
            suggestions: {followup_time: ''},
          },
          has_partner: false,
          house_no: '',
          initials: '--',
          last_name: '',
          latitude: '',
          lead_data: [],
          lead_type: '',
          longitude: '',
          mortgage_calculations: {
            calculator: '',
            data: null,
          },
          notes: [],
          city: '',
          partner_income: '',
          post_code: '',
          province: '',
          rating: 0,
          return_details:{
            admin_comment: '',
            explanation: '',
            reason: '',
            return_date: '',
            return_status: 0,
          },
          returned: 0,
          street: '',
        })
    let displaySpinner  = ref(false)

    const getLeadDetails = async () => {
      displaySpinner.value = true
      axios.post(endPointByRole+'/lead/detail', {id: router.params.id})
          .then( (resp) => {
            if(resp.data.error){
              console.log('Lead details error: '+resp.data.error);
            }
            else{
              updateObject(lead_details, resp.data.data)
              if(!resp.data.data.opened_at && userRole.value === 'is-advisor' && store.state.menu_badges_data.LeadsList > 0){
                store.commit('commit_menu_badges', {key: 'LeadsList', value: (store.state.menu_badges_data.LeadsList-1)})
              }
            }
          })
          .catch( err => {
            console.log('Lead details ajax: '+err)
          })
      .then(()=> displaySpinner.value = false)
    }
    onMounted(getLeadDetails)

    const { t } = useI18n()
    let noteAddingSpinner = ref(false)
    const add_new_note = async () => {
      if(new_note.value.length < 1){
        return false;
      }
      noteAddingSpinner.value = true
      axios.post('/api/advisor/lead/add_note', {id: router.params.id, note: new_note.value})
          .then( (resp) => {
            if(resp.data.error){
              toastController.create({color: 'danger', message: resp.data.error, duration:3000}).then( toast => toast.present())
              console.log('Note adding server error: '+resp.data.error)
            }
            else{
              new_note.value = ''
              lead_details.notes.unshift(resp.data)
              toastController.create({color: 'success', message: t('Thank you! note added'), duration:2000}).then( toast => toast.present())
            }
          })
          .catch(err => {
            toastController.create({color: 'warning', message: t('errors.general_error'), duration:3000}).then( toast => toast.present())
            console.log('Note adding ajax error: '+err)
          })
      .then(() => noteAddingSpinner.value = false)
    }

    let distance = ref(0)
    const updateDistance = (d) => {
      distance.value = d
    }

    const onLeadReturnCancel = () => {
      lead_details.returned = 0
      lead_details.return_details.return_status = 0
      updateTracker('advisor', ['leads_list', 'returned_leads'])
    }

    const onLeadReturnSuccess = (d) => {
      lead_details.returned = 1
      lead_details.return_details.return_date = d.return_date
      lead_details.return_details.reason = d.reason
      lead_details.return_details.explanation = d.explanation
      lead_details.return_details.return_status = 3
      updateTracker('advisor', ['leads_list', 'returned_leads'])
    }

    watch(()=> router.params.id, (n)=>{
      if(n){
        getLeadDetails()
      }
    })

    let quickEmail = reactive({
      to: 0,
      toEmail: '',
      isModalOpen: false,
    })

    const onRatingChange = (rating) => {
      lead_details.rating = rating
    }

    const onLeadDataUpdate = (newData) => {
      updateObject(lead_details, newData)
      lead_details.followup_suggestion.id = null
    }

    const onNewNoteAddition = (data) => {
      lead_details.notes.unshift(data)
    }

    const displayAppointmentFeedbackComp = computed(()=>{
      return (lead_details.lead_type === 'appointment' && userRole.value === 'is-advisor'
          && lead_details.followup_suggestion.id
          && ![5, 8].includes( parseInt(lead_details.followup_suggestion.last_followup.result) ) )
    })

    return {
      currentUserData,
      displayAppointmentFeedbackComp,
      displaySpinner,
      lead_details,
      onLeadDataUpdate,
      onLeadReturnCancel,
      onLeadReturnSuccess,
      onNewNoteAddition,
      onRatingChange,
      isPlatform,
      new_note,
      add_new_note,
      noteAddingSpinner,
      distance,
      quickEmail,
      updateDistance,
      userRole,
    }
  }
});
</script>
