<template>
  <div class="d-block d-md-flex align-items-center">
    <div>
      <ion-datetime
          v-if="platform === 'mobile'"
          display-format="DD/MM/YYYY"
          picker-format="DD/MM/YYYY" class="form-control"
          :value="date || ''"
          v-model="newData.date">

      </ion-datetime>
      <vue-flatpickr v-else v-model="newData.date" id="d_range_f" class="form-control" :config="vueFlatPickerConfig"></vue-flatpickr>
    </div>
    <div class="d-flex d-md-inline align-items-center mt-2 mt-md-0">
      <span class="mx-2">{{$t('at')}}</span>
      <div>
        <b-select v-model="newData.hour" class="px-2">
          <option v-for="x in 15" :key="'lf_t_hr'+x" :value="(x+6) < 10 ? '0'+(x+6) : (x+6)">{{ (x+6) > 9 ? (x+6) : '0'+(x+6) }}</option>
        </b-select>
      </div>
      <span class="mx-1">:</span>
      <div>
        <b-select v-model="newData.min" class="px-2">
          <option value="00">00</option>
          <option v-for="x in 11" :key="'lf_t_hr'+x" :value="(x < 2 ) ? '0'+(x*5): x*5">{{ x > 1 ? x*5 : '0'+(x*5)}}</option>
        </b-select>
      </div>
    </div>
  </div>
</template>

<script>
import {IonDatetime} from "@ionic/vue"
import BSelect from "@core/components/bp-form/components/BSelect";
import VueFlatpickr from "vue-flatpickr-component"
import {Dutch} from "flatpickr/dist/l10n/nl";
import {english} from "flatpickr/dist/l10n/default";
import {useStore} from "vuex";
import {reactive, watch} from "vue";
import useCurrentPlatform from "@/store/currentPlatform";

export default {
  components:{BSelect, IonDatetime, VueFlatpickr},
  props:{
    date: null,
    hour: null,
    min: null,
    suggestedDefaultDate: String,
  },
  emits: ['updateData'],
  setup(props, {emit}){

    const { platform } = useCurrentPlatform()
    const store = useStore()
    let today = new Date()
    const vueFlatPickerConfig = {
      //dateFormat: 'Y-m-d',
      altFormat: 'd/m/Y',
      altInput: true,
      enableTime: false,
      minDate: new Date(),
      //defaultDate: props.suggestedDefaultDate || today.setDate(today.getDate()+1),
      time_24hr: true,
      locale: store.state.appConfig.lang === 'nl' ? Dutch: english,
    }

    let newData = reactive({
      date: props.date || props.suggestedDefaultDate || new Date(today.setDate(today.getDate()+1)).toISOString().split('T')[0] || '',
      hour: props.hour || '',
      min: props.min || '',
    })
    emit('updateData', newData)

    watch(newData, ()=>{
      emit('updateData', newData)
    })

    return{
      newData,
      platform,
      vueFlatPickerConfig,
    }
  }
}
</script>
