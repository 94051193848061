<template>
  <row class="g-gs">
    <column :lg="6">
      <card no-padding>
        <template v-slot:grouped>
          <card-inner><card-header :title="$t('This lead has been returned')"></card-header></card-inner>
          <card-inner>
            <div class="fw-500 nk-text-dark">Lead#</div>
            <div class="text-primary">{{ leadNumber }}</div>
            <div class="fw-500 nk-text-dark mt-2">{{ $t('Returned On') }}</div>
            <div class="text-muted">{{ returnDetails.return_date }}</div>
            <div class="fw-500 nk-text-dark mt-2">{{ $t('Reason') }}</div>
            <div class="text-muted">{{ $t(returnDetails.reason) }}</div>
            <div class="fw-500 nk-text-dark mt-2">{{ $t('Explanation') }}</div>
            <div class="text-muted">{{ returnDetails.explanation }}</div>
          </card-inner>
        </template>
      </card>
    </column>
    <column :lg="6">
      <card no-padding full-height>
        <template v-slot:grouped>
          <card-inner><card-header title="Return Status"></card-header></card-inner>
          <card-inner class="lh-n">
            <div>
              <div class="fw-500 nk-text-dark mb-1">{{ $t('Approval')+' '+$t('Status') }}</div>
              <badge type="primary" class="py-1 px-2" outline dim v-if="returnDetails.return_status === 1">
                <nio-icon icon="ni-check-circle-cut" class="mr-1"></nio-icon> {{ $t('Return Accepted') }}
              </badge>
              <badge type="danger" outline dim class="py-1 px-2" v-else-if="returnDetails.return_status === 2">
                <nio-icon icon="ni-curve-left-down" class="mr-1"></nio-icon> {{ $t('Rejected') }}
              </badge>
              <badge type="dark" dim class="py-1 px-2" v-else>
                <nio-icon icon="ni-circle" class="mr-1"></nio-icon> {{ $t('Pending Approval') }}
              </badge>
            </div>
            <template v-if="returnDetails.return_status !== 3">
              <div class="fw-500 nk-text-dark mt-2">{{ $t('Comment') }}</div>
              <div class="text-muted">Admin comment goes here</div>
            </template>
            <div class="fw-500 nk-text-dark mt-2">{{ $t('Refund Status') }}</div>
            <div class="text-muted">TODO</div>
            <div v-if="returnDetails.return_status === 3" class="mt-4">
              <nk-button type="primary" dim size="sm" v-on:click="F_cancelReturnRequest">
                <nio-icon icon="ni-curve-up-left" class="mr-1"></nio-icon> {{ $t('Cancel Request') }}
              </nk-button>
            </div>
          </card-inner>
        </template>
      </card>
    </column>
  </row>
</template>

<script>
import Card from "@core/components/cards/Card";
import CardHeader from "@core/components/cards/CardHeader";
import CardInner from "@core/components/cards/components/CardInner";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";
import Badge from "@core/components/badge/Badge";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import useLeadFunctions from "@core/comp-functions/LeadFunctions"

export default {
  components: {NkButton, NioIcon, Badge, Column, Row, CardInner, CardHeader, Card},
  props: {
    returnDetails: {
      type: Object,
      required: true,
    },
    advisorLeadId: {
      required: true,
    },
    leadNumber: null,
  },
  emits: ['returnCanceled'],
  setup(props, {emit}){

    const { cancelReturnRequest} = useLeadFunctions()
    const F_cancelReturnRequest = () => {
      cancelReturnRequest(props.advisorLeadId, (success) => {
        if(success){
          emit('returnCanceled')
        }
      })
    }

    return {
      F_cancelReturnRequest,
    }
  }
}
</script>
