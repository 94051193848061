<template>
  <row class="g-gs">
    <column default="12">
      <card>
        <template v-if="currentStep === '1st'">
          <template v-if="followupFlow.step1.current_interface === 'appointment_followup'">
            <template v-if="leadData.next_followup.future_date">
              <h4 style="color: #00c58c;">{{ $t("lead_followup_wizard.upcoming_followup_type_reminder", {followup_type: $t(leadData.next_followup.type).toLowerCase() })}}</h4>
              <p> {{$t(leadData.next_followup.type)+' '+$t('at')+' '+leadData.next_followup.future_date}}</p>
            </template>
            <template v-else>
              <h4 style="color: #00c58c;">{{ $t("lead_followup_wizard.how_was_your_appointment")}}</h4>
              <div class="my-4">
                <nk-button type="danger" outline round class="mr-2 mb-2 mb-md-0"
                           :class="{'d-block w-100': $isHybridPlatform}"
                           v-on:click="submitAppointmentResult('uninterested')">{{$t('Lead was uninterested')}}</nk-button>
                <nk-button type="primary" outline round
                           :class="{'d-block w-100': $isHybridPlatform}"
                           v-on:click="submitAppointmentResult('finalized')">{{$t('Finalized')}}</nk-button>
              </div>
            </template>
          </template>
          <template v-else>
            <h3 style="color: #00c58c;">{{ $t("It's time to reach out to your lead!")}}</h3>
            <p v-if="leadData.last_followup.time && leadData.next_followup.date">
              {{ $t('lead_followup_wizard.you_had_a_followup_how_did_it_go', {date: leadData.last_followup.date, time: leadData.last_followup.time, followup_type: $t('Follow up').toLowerCase()})}}
            </p>
            <p v-else-if="leadData.last_followup.time">
              {{$t('lead_followup_wizard.last_followup_unreachable_lead_try_again_if_u_want', {date: leadData.last_followup.date, time: leadData.last_followup.time})}}
            </p>
            <p v-else>{{ $t('lead_followup_modal.followup_asap_is_crucial_msg')}}<br/>{{ $t('lead_followup_modal.time_since_received_lead', {time: leadData.received_time}) }}</p>
            <div class="my-2">
              <nk-button type="secondary" outline class="mr-2">{{ $t('Attempt') }} #{{leadData.attempt}}</nk-button>
            </div>
            <div class="mt-4">
              <p class="fw-bold d-flex align-items-center">
                {{ $t('Time of call')}}:
                <ion-spinner v-if="spinners.gettingServerTime" style="width: 18px;" class="ml-2 mt-n1"></ion-spinner>
                <badge type="primary" outline v-else-if="currentAttempt.right_now" class="ml-2">{{currentAttempt.serverDate+' '+$t('at')+' '+currentAttempt.serverTime}}</badge>
              </p>
              <div class="d-block d-md-flex align-items-center">
                <nk-button type="secondary" outline round
                           class="mr-2"
                           :class="{active: currentAttempt.right_now, 'w-100 d-block': $isHybridPlatform}"
                           v-on:click="setCurrentAttemptToNow">{{ $t('Right now') }}</nk-button>
                <b class="mr-2 my-2 my-md-0 d-block d-md-inline text-center text-md-left">{{$t('Or')}}</b>
                <div>
                  <ion-datetime
                      v-if="platform === 'mobile'"
                      display-format="DD/MM/YYYY"
                      picker-format="DD/MM/YYYY" class="form-control"
                      :value="currentAttempt.date+' '+currentAttempt.hour+':'+currentAttempt.min"
                      v-model="currentAttempt.date">

                  </ion-datetime>
                  <vue-flatpickr v-else v-model="currentAttempt.date" id="f_date1" class="form-control" :config="vueFlatPickerConfig" :placeholder="$t('choose date')"></vue-flatpickr>
                </div>
                <div :class="{'d-flex align-items-center my-2': $isHybridPlatform}">
                  <span class="mx-2">{{$t('at')}}</span>
                  <div>
                    <b-select v-model="currentAttempt.hour" class="px-2">
                      <option v-for="x in 15" :key="'lf_t_hr'+x" :value="(x+6) < 10 ? '0'+(x+6) : (x+6)">{{ (x+6) > 9 ? (x+6) : '0'+(x+6) }}</option>
                    </b-select>
                  </div>
                  <span class="mx-1">:</span>
                  <div>
                    <b-select v-model="currentAttempt.min" class="px-2">
                      <option value="00">00</option>
                      <option v-for="x in 11" :key="'lf_t_hr'+x" :value="(x < 2 ) ? '0'+(x*5): x*5">{{ x > 1 ? x*5 : '0'+(x*5)}}</option>
                    </b-select>
                  </div>
                </div>
                <div class="ml-2">
                  <nk-button type="success" round outline
                             v-on:click="validateCurrentAttempt"
                             :class="{'d-block w-100': $isHybridPlatform}">{{$t('Confirm')}}</nk-button>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="currentStep === '2nd'">
          <nk-button type="secondary" outline round class="mb-5" v-on:click="currentStep='1st'"><nio-icon icon="ni-chevron-left"></nio-icon> {{$t('Back')}}</nk-button>
          <h4>{{$t('Did you reach the lead?')}}</h4>
          <div class="d-flex mt-3 align-items-center">
            <nk-button type="success" outline is-icon-only-button class="mr-2" round v-on:click="[followupFlow.step2.was_lead_reachable=true,currentStep='3rd']">
              <nio-icon icon="ni-thumbs-up"></nio-icon>
            </nk-button>
            <nk-button type="warning" outline is-icon-only-button round v-on:click="[followupFlow.step2.was_lead_reachable=false,currentStep='3rd']">
              <nio-icon icon="ni-thumbs-down"></nio-icon>
            </nk-button>
          </div>
        </template>
        <template v-if="currentStep === '3rd'">
          <div v-if="followupFlow.step2.was_lead_reachable">
            <template v-if="!followupFlow.step3.call_result.length">
              <nk-button type="secondary" outline round class="mb-5" v-on:click="currentStep='2nd'"><nio-icon icon="ni-chevron-left"></nio-icon> {{$t('Back')}}</nk-button>
              <h4>{{$t('How did the call go?')}}</h4>
              <div class="mt-2">
                <nk-button type="success" round outline
                           class="mr-2 mb-2 mb-md-0"
                           :class="{'d-block w-100': $isHybridPlatform}"
                           v-on:click="followupFlow.step3.call_result='interested'">
                  {{$t('Lead was interested')}} <nio-icon icon="ni-thumbs-up" class="ml-1 mt-n1"></nio-icon>
                </nk-button>
                <nk-button type="danger" round outline
                           class="mr-2 mb-2 mb-md-0"
                           :class="{'d-block w-100': $isHybridPlatform}"
                           v-on:click="followupFlow.step3.call_result='uninterested'">
                  {{$t('Lead was uninterested')}} <nio-icon icon="ni-thumbs-down" class="ml-1"></nio-icon>
                </nk-button>
                <nk-button type="info" round outline
                           :class="{'d-block w-100': $isHybridPlatform}"
                           v-on:click="followupFlow.step3.call_result='requested_callback'">
                  {{$t('Requested to callback at another time')}}
                </nk-button>
              </div>
            </template>
            <div v-else-if="followupFlow.step3.call_result==='interested'">
              <template v-if="followupFlow.step3.reachable.interested.visible_component === 'whats_next'">
                <nk-button type="secondary" outline round class="mb-5" v-on:click="followupFlow.step3.call_result=''"><nio-icon icon="ni-chevron-left"></nio-icon> {{$t('Back')}}</nk-button>
                <h4>{{$t("Great! What's next?")}}</h4>
                <div class="mt-3">
                  <nk-button type="info" outline round
                             class="mb-3"
                             :class="{'d-block w-100': $isHybridPlatform}"
                             v-on:click="followupFlow.step3.reachable.interested.visible_component='set_appointment'">{{$t('Set appointment in Agenda')}}</nk-button><br/>
                  <nk-button type="info" outline round
                             :class="{'d-block w-100': $isHybridPlatform}"
                             v-on:click="composeMailModal=true">{{$t('Send email with information')}}</nk-button>
                </div>
                <div v-if="userRole === 'is-advisor'" class="mt-3 text-center" style="max-width: 220px;">
                  <small class="d-block fw-bold">{{$t('Rate this ' + leadType)}}</small>
                  <star-rating :rating="starRating" :increment="0.5" :star-size="20" :show-rating="false" @update:rating="setRating" class="justify-content-center"></star-rating>
                </div>
              </template>
              <template v-else-if="followupFlow.step3.reachable.interested.visible_component === 'set_appointment'">
                <nk-button type="secondary" outline round class="mb-5" v-on:click="followupFlow.step3.reachable.interested.visible_component='whats_next'"><nio-icon icon="ni-chevron-left"></nio-icon> {{$t('Back')}}</nk-button>
                <h4 class="mb-3">{{$t('Set appointment in Agenda')}}</h4>
                <lead-next-followup :date="nextFollowup.date" :hour="nextFollowup.hour" :min="nextFollowup.min" @update-data="syncNextFollowup"></lead-next-followup>
                <div class="mt-4">
                  <nk-button type="success" round outline class="mr-2" v-on:click="submitFollowupResult">{{$t('Schedule appointment')}}</nk-button>
                  <nk-button v-if="userRole === 'is-ap-agent'" type="light" round outline v-on:click="F_advisorsAndAvailability">{{ $t('See advisors availability') }}</nk-button>
                </div>
              </template>
            </div>
            <div v-else-if="followupFlow.step3.call_result==='uninterested'">
              <nk-button type="secondary" outline round class="mb-5" v-on:click="followupFlow.step3.call_result=''"><nio-icon icon="ni-chevron-left"></nio-icon> {{$t('Back')}}</nk-button>
              <h4>{{$t('What was the reason?')}}</h4>
              <div class="mt-2 d-flex">
                <div>
                  <b-select v-model="followupFlow.step3.reachable.uninterested.reason">
                    <option value="">{{$t('Choose an option')}}</option>
                    <option v-for="(reason,i) in leadUninterestedReasons" :key="'lur'+i" :value="reason.id">{{$t(reason.reason)}}</option>
                    <option value="different">{{$t('Different')}}...</option>
                  </b-select>
                  <textarea v-model="followupFlow.step3.reachable.uninterested.different_reason" v-if="followupFlow.step3.reachable.uninterested.reason === 'different'" class="form-control mt-2" :placeholder="$t('Reason')"></textarea>
                  <nk-button type="success" round outline
                             class="mt-4 mt-md-2"
                             :class="{'d-block w-100': $isHybridPlatform}"
                             v-on:click="submitFollowupResult">{{$t('Submit')}}</nk-button>
                </div>
              </div>
            </div>
            <div v-else-if="followupFlow.step3.call_result==='requested_callback'">
              <nk-button type="secondary" outline round class="mb-5" v-on:click="followupFlow.step3.call_result=''"><nio-icon icon="ni-chevron-left"></nio-icon> {{$t('Back')}}</nk-button>
              <p class="fw-bold">{{$t('When are you going to call again?')}}</p>
              <lead-next-followup :date="nextFollowup.date" :hour="nextFollowup.hour" :min="nextFollowup.min" @update-data="syncNextFollowup"></lead-next-followup>
              <nk-button type="success" round outline
                         class="mt-2"
                         :class="{'w-100 d-block': $isHybridPlatform}"
                         v-on:click="submitFollowupResult">{{$t('Schedule follow up')}}</nk-button>
            </div>
          </div>
          <div v-else>
            <nk-button type="secondary" outline round class="mb-5" v-on:click="currentStep='2nd'"><nio-icon icon="ni-chevron-left"></nio-icon> {{$t('Back')}}</nk-button>
            <template v-if="leadData.attempt < 15">
              <p class="fw-bold" v-if="leadData.last_followup.time">
                {{ $t('lead_followup_wizard.last_called_time', {time: leadData.last_followup.time, time_name: leadData.last_followup.time_name})}}<br/>
                {{ $t('lead_followup_wizard.next_call_time_suggestion', {time_name: leadData.suggestions.followup_time}) }}
              </p>
              <p class="fw-bold" v-else>{{$t('lead_followup_wizard.lead_unreachable_msg1')}}</p>
              <lead-next-followup :date="nextFollowup.date" :hour="nextFollowup.hour" :min="nextFollowup.min" @update-data="syncNextFollowup"></lead-next-followup>
              <div class="my-4">
                <nk-button type="success" round outline class="mr-2" v-on:click="submitFollowupResult">{{$t('Schedule follow up')}}</nk-button>
                <nk-button v-if="leadData.attempt > 4" type="warning" round outline v-on:click="skipFollowup">{{$t('Skip')}}</nk-button>
              </div>
            </template>
            <template v-else>
              <p class="fw-bold">{{$t('lead_followup_wizard.lead_unreachable_msg2')}}</p>
              <textarea class="form-control mt-2 mb-3" v-model="followupFlow.step3.unreachable.feedback"></textarea>
              <nk-button type="success" round outline class="mt-2" v-on:click="submitFollowupResult">{{$t('Submit feedback')}}</nk-button>
            </template>
          </div>
        </template>
      </card>
    </column>
  </row>

  <send-quick-email :to-id="advisorLeadId" receiver-type="advisor_lead" :modal-open="composeMailModal" @on-modal-close="composeMailModal=false"></send-quick-email>

</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import Card from "@core/components/cards/Card";
import {Column,Row} from "@core/layouts";
import 'flatpickr/dist/flatpickr.css';
import VueFlatpickr from "vue-flatpickr-component"
import useCurrentPlatform from "@/store/currentPlatform";
import {IonDatetime, isPlatform, loadingController, modalController, toastController} from "@ionic/vue"
import {Dutch} from "flatpickr/dist/l10n/nl";
import {english} from "flatpickr/dist/l10n/default";
import {useStore} from "vuex";
import BSelect from "@core/components/bp-form/components/BSelect";
import {useI18n} from "vue-i18n";
import LeadNextFollowup from "@/views/lead-details/LeadNextFollowup";
import axios from "@/libs/axios";
import StarRating from "vue-star-rating";
import useCommonFunc from "@core/comp-functions/common"
import useLeadFunctions from "@core/comp-functions/LeadFunctions"
import SendQuickEmail from "@/views/email-templates/SendQuickEmail";
import Badge from "@core/components/badge/Badge";
import AdvisorsAndAvailability from "@/views/admin/Leads/components/AdvisorsAndAvailability";

export default {
  components: {
    Badge,
    SendQuickEmail, LeadNextFollowup, BSelect, Column, Row, Card, IonDatetime, StarRating, VueFlatpickr},
  props:{
    leadData: Object,
    leadType: {
      required: true,
      type: String,
    },
    starRating: {
      required: true,
    },
    advisorLeadId: {
      required: true,
    },
  },
  emits: ['onRatingChange', 'onLeadDataUpdate', 'onScrollBottom'],
  setup(props, {emit}){

    let currentStep = ref('1st')
    let composeMailModal = ref(false)
    const { platform } = useCurrentPlatform()
    const store = useStore()
    const {t} = useI18n()
    const {getServerTime}  = useCommonFunc()
    const {setLeadRating} = useLeadFunctions()

    let spinners = reactive({
      gettingServerTime: false,
    })

    const vueFlatPickerConfig = {
      dateFormat: 'Y-m-d',
      altFormat: 'd/m/Y',
      altInput: true,
      enableTime: false,
      minDate: props.leadData.last_followup.date_iso || props.leadData.assigned_date_ISO,
      maxDate: new Date(),
      locale: store.state.appConfig.lang === 'nl' ? Dutch: english,
    }

    let currentAttempt = reactive({
      right_now: false,
      serverDate: '',
      serverDateISO: '',
      serverTime: '',
      date: '',
      hour: '',
      min: ''
    })
    watch(currentAttempt, (n)=>{
      if(n.date || n.hour || n.min){
        currentAttempt.right_now = false
      }
    })
    const validateCurrentAttempt = () => {
      if(!currentAttempt.right_now && (!currentAttempt.date || !currentAttempt.hour || !currentAttempt.min)){
        toastController.create({header: t('Error'), message: t('lead_followup_wizard.current_attempt_validation_error'), color: 'danger', position: "top", duration: 4000})
        .then((ts)=> ts.present())
      }
      else{
        currentStep.value = '2nd'
      }
    }

    let nextFollowup = reactive({
      type: 'followup',
      date: '',
      hour: '',
      min: '',
    })
    
    let followupFlow = reactive({
      step1:{
        current_interface: props.leadData.next_followup.future_date || props.leadData.next_followup.type === 'Appointment' ? 'appointment_followup' : 'time_to_reach_ur_lead',
        appointment_result: '',
      },
      step2:{
        was_lead_reachable: false,
      },
      step3:{
        call_result: '',
        reachable:{
          uninterested:{
            reason: '',
            different_reason: ''
          },
          interested:{
            visible_component: 'whats_next',
          }
        },
        unreachable:{
          skip_followup: false,
          feedback: '',
        }
      }
    })

    const setCurrentAttemptToNow = () => {
      currentAttempt.right_now = true

      spinners.gettingServerTime = true
      getServerTime().then((d)=>{
        currentAttempt.serverDate    = d.date_formatted
        currentAttempt.serverDateISO = d.date_ISO
        currentAttempt.serverTime    = d.time
      })
      .catch(()=>{})
      .then(()=> spinners.gettingServerTime = false)
      currentAttempt.date = currentAttempt.hour = currentAttempt.min = ''
    }

    const syncNextFollowup = (d)=>{
      nextFollowup.date = d.date
      nextFollowup.hour = d.hour
      nextFollowup.min  = d.min
    }

    const submitFollowupResult = async () => {

      let params = {}
      if(followupFlow.step1.current_interface === 'appointment_followup'){
        params.action = 'appointment_result'
        params.result = followupFlow.step1.appointment_result
      }
      else{
        params.action = 'followup_result'
        params.call_time = JSON.stringify(currentAttempt)
        params.was_lead_reachable = followupFlow.step2.was_lead_reachable
        params.next_followup = nextFollowup
        params.result = followupFlow.step3.call_result
        if(followupFlow.step3.unreachable.skip_followup){
          params.skip_followup = true;
        }
        if(!params.was_lead_reachable && props.leadData.attempt > 15){
          params.feedback = followupFlow.step3.unreachable.feedback
        }
        if(followupFlow.step2.was_lead_reachable){
          if(params.result === 'uninterested'){
            let temp = followupFlow.step3.reachable.uninterested
            params.uninterested_reason = temp.reason !== 'different' ? temp.reason : temp.different_reason
          }
          else if(params.result !== 'requested_callback'){
            params.next_followup.type = 'appointment'
          }
        }
        params.next_followup = JSON.stringify(nextFollowup)
      }

      const loading = await loadingController.create({message: t('Please wait...')})
      await loading.present()

      const endPoint = {
        'is-advisor': 'advisor',
        'is-ap-agent': 'ap_agent',
      }

      axios.put('/api/'+endPoint[store.state.auth.user_role]+'/lead/'+props.leadData.id+'/submit_followup_result', params)
      .then(res=>{
        emit('onLeadDataUpdate', res.data.data)
      })
      .catch(er=>{
        const msg = er?.response?.data ? er.response.data.message : t('errors.general_error')
        toastController.create({header: t('Error'), message: msg, color:"danger", position: 'top', duration: 4000}).then((ts)=> ts.present())
      })
      .then(()=> loading.dismiss())
    }
    const submitAppointmentResult = (result) => {
      followupFlow.step1.appointment_result = result
      submitFollowupResult()
    }
    const skipFollowup = ()=>{
      followupFlow.step3.unreachable.skip_followup = true
      submitFollowupResult()
    }
    const setRating = (rating) => {
      setLeadRating(props.advisorLeadId, rating)
      .then(()=>{
        emit('onRatingChange', rating)
      })
      .catch(()=>{})
    }

    const leadUninterestedReasons = computed(()=>{
      return store.state.general_data.lead_uninterested_reasons
    })

    const userRole = computed(()=>{
      return store.state.auth.user_role
    })

    const scrollToBottom = ()=>{
      emit('onScrollBottom')
    }

    const F_advisorsAndAvailability = async () => {
      const m = await modalController.create({
        component: AdvisorsAndAvailability,
        componentProps:{
          leadId: props.leadData.id,
        },
        cssClass: 'advisors_and_availability_modal'+(isPlatform('desktop') ? ' modal-web' : ''),
      })
      await m.present()
    }

    return{
      composeMailModal,
      currentAttempt,
      currentStep,
      followupFlow,
      F_advisorsAndAvailability,
      leadUninterestedReasons,
      nextFollowup,
      platform,
      scrollToBottom,
      setCurrentAttemptToNow,
      setRating,
      skipFollowup,
      spinners,
      submitAppointmentResult,
      submitFollowupResult,
      syncNextFollowup,
      userRole,
      validateCurrentAttempt,
      vueFlatPickerConfig,
    }
  }
}
</script>
