<template>
  <ion-modal
      :is-open="isModalOpen"
      @didDismiss="closeModal"
      :css-class="isDesktop ? 'modal-web': ''">
    <i-modal title="Return this lead" @modal-close="closeModal">
      <p>{{$t('Please provide the following details.')}}</p>
      <form-group>
        <label class="overline-title" for="l_r_r">{{$t('Select a reason')}}</label>
        <b-select id="l_r_r" v-model="leadReturnData.reason">
          <option value="0">{{$t('Select a reason')}}</option>
          <option v-for="reason in store.state.general_data.lead_return_reasons" :key="reason.id" :value="reason.id">{{ $t(reason.reason) }}</option>
        </b-select>
      </form-group>
      <form-group>
        <label class="overline-title" for="l_r_e">{{$t('Explanation')}}</label>
        <textarea class="form-control" id="l_r_e" v-model="leadReturnData.explanation"></textarea>
      </form-group>
      <template v-slot:footer>
        <nk-button type="dark" class="mr-2" v-on:click="returnLead">{{$t('Submit')}}</nk-button>
        <nk-button type="light" v-on:click="closeModal">{{$t('Cancel')}}</nk-button>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import {alertController, IonModal, isPlatform, loadingController, toastController} from "@ionic/vue"
import IModal from "@core/components/ion-modal/IModal";
import FormGroup from "@core/layouts/form-group/FormGroup";
import {useStore} from "vuex"
import BSelect from "@core/components/bp-form/components/BSelect";
import {reactive, toRef} from "vue";
import NkButton from "@core/components/button/NkButton";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";

export default {
  components: {NkButton, BSelect, FormGroup, IModal, IonModal},
  props: {
    isModalOpen: Boolean,
    advisorLeadId: {
      required: true,
    }
  },
  emits:['onModalClose', 'onSuccess'],
  setup( props, {emit}){

    const{t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const store = useStore()
    let leadReturnData = reactive({
      reason: 0,
      explanation: '',
      lead_id: toRef(props, 'advisorLeadId')
    })
    const closeModal = () => {
      emit('onModalClose')
    }
    const returnLead = async () => {
      const l = await loadingController.create({message: t('Please wait...')})
      const al = await alertController.create({
        header: t('Error'),
        cssClass: isDesktop ? 'alert-web' : '',
        message:"", buttons:[
          {
            text: t('OK'),
            cssClass: isDesktop ? 'alert-btn-light' : '',
          },
        ]
      })
      if(parseInt(leadReturnData.reason) < 1){
        al.message = t('Select return reason')
      }
      else if(leadReturnData.explanation.length < 10){
        al.message = t('Provide a valid explanation')
      }
      if(al.message){
        await al.present()
        return false
      }
      await l.present()
      axios.post('/api/advisor/lead/return', leadReturnData)
      .then(r=>{
        if(r.data.success){
          let temp = Object.assign({}, leadReturnData)
          temp.return_date = r.data.data.return_date
          temp.reason = r.data.data.reason_text
          toastController.create({
            header: t('Success'),
            message: t('thank_you_messages.lead_return_requested'),
            color: "tertiary",
            duration: 5000,
            position: 'top',
          }).then((ts)=> ts.present())
          emit('onSuccess', temp)
        }
        else{
          al.message = r.data.message
          al.present()
        }
      })
      .catch(er=>{
        al.message = er.response ? er.response.status+' '+er.response.statusText : er
        al.present()
      })
      .then(()=>l.dismiss())
    }

    return {
      isDesktop,
      closeModal,
      leadReturnData,
      returnLead,
      store,
    }
  },
}
</script>
