<template>
  <accordion id="lead_notes" class="is-separate">
    <accordion-item v-for="(note, index) in notes" :key="note.id" class="light-shadow">
      <accordion-item-head :data-target="'#note-'+index" :collapsed="index > 0">
        <div class="d-md-flex align-items-center">
          <div class="flex-fill">
            {{ $t('Note')+' #'+(notes.length - index) }}
            <div class="small text-muted" v-if="note.added_by && 'you' !== note.added_by">{{ $t('added by')+' '+$t(note.added_by) }}</div>
          </div>
          <div class="pr-md-5">
            <span class="font-weight-normal">{{ $t('on') }}: </span>
            {{ note.added_date }}, <span class="font-weight-normal">{{ $t('at')}}</span> {{ note.added_time }}
          </div>
        </div>
      </accordion-item-head>
      <accordion-item-body :id="'note-'+index" data-parent="#lead_notes" :collapsed="index > 0">
        <div v-html="note.note"></div>
      </accordion-item-body>
    </accordion-item>
  </accordion>
</template>

<script>
import Accordion from "@core/components/accordion/Accordion";
import AccordionItem from "@core/components/accordion/components/AccordionItem";
import AccordionItemHead from "@core/components/accordion/components/AccordionItemHead";
import AccordionItemBody from "@core/components/accordion/components/AccordionItemBody";
export default {
  components: {AccordionItemBody, AccordionItemHead, AccordionItem, Accordion},
  props:{
    notes: Array,
  },
}
</script>
