<template>
  <card>
    <h6 class="mt-0">{{ $t('Status') }}</h6>
    <row>
      <column auto="" class="flex-fill pr-0">
        <lead-status
            v-on:click="editLeadStatus()"
            :status-id="lead_data.status_id"
            :status-text="lead_data.status_text"
            :event-type="lead_data.event_type"
            :event-date="lead_data.event_date"
            :event-time="lead_data.event_time"
            class="border border-1 p-1 font-weight-bold text-center small h-100"
            style="cursor:pointer;">
            </lead-status>
      </column>
    </row>
  </card>
  <followup-logs :followup-logs="lead_data.followup_logs"></followup-logs>
  <card>
    <div v-if="lead_data.form_name" class="mb-3">
      <badge type="primary" class="fw-bold" dim style="font-size: 11px;">{{ $t('Form')+': '+ lead_data.form_name}}</badge>
    </div>
    <div>
      <div class="small font-weight-bold nk-text-dark">{{ $t( lead_data.lead_type === 'lead' ? 'Lead' : 'Appointment' + ' received on') }}:</div>
      <div class="small nk-text-muted">{{ lead_data.received_date }} {{ $t('on') }} {{ lead_data.received_time}}</div>
    </div>
    <div class="mt-3" v-if="userRole === 'is-advisor'">
      <div class="small font-weight-bold nk-text-dark">{{ $t('Rate this ' + lead_data.lead_type ) }}</div>
      <star-rating :rating="lead_data.rating" :increment="0.5" :star-size="20" :show-rating="false" @update:rating="setRating"></star-rating>
    </div>
    <div class="mt-4" v-if="userRole === 'is-advisor' && lead_data.archived === 0 && lead_data.lead_type === 'lead' && lead_data.returned === 0 && parseInt(lead_data.assigned_since_days) < 7">
      <nio-icon icon="ni-curve-left-up"></nio-icon> <a href="javascript:;" class="text-danger" v-on:click="isLeadReturnModalOpen=true">{{ $t('Return this lead') }}</a>
    </div>
  </card>

  <return-lead :advisor-lead-id="lead_data.AL_id" :is-modal-open="isLeadReturnModalOpen" @on-modal-close="isLeadReturnModalOpen=false" @on-success="onLeadReturnSuccess"></return-lead>
</template>

<script>
import Card from "@core/components/cards/Card";
import {Row, Column } from "@core/layouts"
//import { BSelect} from "@core/components/bp-form"
import LeadStatus from "@/views/components/LeadStatus";

import {defineComponent, ref} from "vue";
import {useStore} from "vuex"
import StarRating from "vue-star-rating"

import {
  isPlatform
} from '@ionic/vue';
import NioIcon from "@core/components/nio-icon/NioIcon";
import ReturnLead from "@/views/lead-details/ReturnLead";
import Badge from "@core/components/badge/Badge";
import FollowupLogs from "@/views/lead-details/components/FollowupLogs";
import useLeadFunctions from "@core/comp-functions/LeadFunctions"
import useStoreHelper from '@/store/helper'

export default defineComponent({
  components: {
    FollowupLogs,
    Badge,
    ReturnLead,
    NioIcon,
    Card,
    Column,
    LeadStatus,
    Row,
    StarRating,
  },
  props: {
    lead_data: {
      type: Object,
      required: true,
    },
  },
  emits: ['onLeadReturnSuccess', 'onRatingChange'],
  setup( props, context ){

    const isDesktop = isPlatform('desktop')
    const store = useStore()
    const lead_all_statuses = store.state.general_data.leads_statuses
    let isLeadReturnModalOpen = ref(false)
    const {setLeadRating} = useLeadFunctions()
    const{userRole} = useStoreHelper()

    const onLeadReturnSuccess = (d) => {
      isLeadReturnModalOpen.value = false // Close the modal
      context.emit('onLeadReturnSuccess', d)
    }

    const setRating = (rating)=>{
      setLeadRating(props.lead_data.AL_id, rating)
          .then(()=>{
            context.emit('onRatingChange', rating)
          })
          .catch(()=>{})
    }

    return {
      isLeadReturnModalOpen,
      lead_all_statuses,
      isDesktop,
      onLeadReturnSuccess,
      setRating,
      userRole,
    }
  }
})
</script>
